import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import SchoolDashboard from './SchoolDashboard';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_BACKEND_URL;
console.log('Current API_URL:', API_URL);

const customIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function SetViewOnDataLoad({ schools }) {
  const map = useMap();
  useEffect(() => {
    if (schools.length > 0) {
      const bounds = L.latLngBounds(schools.map(school => [school.latitude, school.longitude]));
      map.fitBounds(bounds, { padding: [100, 100] });
    }
  }, [schools, map]);
  return null;
}

function Map() {
  const [schools, setSchools] = useState([]);
  const [mapCenter] = useState([39.8283, -98.5795]);
  const [error, setError] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [, setEsriData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchools = async () => {
      const token = localStorage.getItem('access_token');
      console.log('Current token:', token ? 'Token exists' : 'No token found');

      if (!token) {
        console.error('No access token found in localStorage');
        setError('No access token found. Please log in again.');
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/schools`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('Response status:', response.status);
        console.log('Response headers:', response.headers);

        if (!response.ok) {
          if (response.status === 401) {
            console.error('Unauthorized access. Token might be invalid or expired.');
            localStorage.removeItem('access_token'); // Clear the invalid token
            navigate('/login');
            return;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Fetched data:', data);

        const validSchools = data.filter(school => 
          school.latitude && school.longitude && 
          !isNaN(school.latitude) && !isNaN(school.longitude)
        );
        setSchools(validSchools);
      } catch (error) {
        console.error('Error fetching school data:', error);
        setError('Failed to load school data. Please try again later.');
      }
    };

    fetchSchools();
  }, [navigate]);


  const handleSchoolClick = (school) => {
    console.log("School clicked:", school);
    
    const token = localStorage.getItem('access_token');
    
    // Fetch ESRI data for the selected school
    fetch(`${API_URL}/school/${school.ncessch}/esri-data`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched ESRI data:', data);
        setEsriData(data);
        setSelectedSchool({...school, esriData: data});
      })
      .catch(error => {
        console.error('Error fetching ESRI data:', error);
        setError('Failed to load ESRI data. Please try again later.');
      });
  };

  const handleBackToAllSchools = () => {
    setSelectedSchool(null);
    setEsriData(null);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (selectedSchool) {
    return <SchoolDashboard school={selectedSchool} onBack={handleBackToAllSchools} apiUrl={API_URL} />;
  }

  return (
    <MapContainer center={mapCenter} zoom={10} style={{ height: "800px", width: "100%" }}>
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      />
      {schools.map((school, index) => (
        <Marker 
          key={index} 
          position={[school.latitude, school.longitude]} 
          icon={customIcon}
          eventHandlers={{
            click: () => handleSchoolClick(school),
          }}
        >
          <Tooltip direction="top" offset={[0, -20]} opacity={1}>
            <strong>{school.name}</strong><br />
            Enrollment: {school.enrollment !== null ? school.enrollment : 'Not available'}
          </Tooltip>
        </Marker>
      ))}
      <SetViewOnDataLoad schools={schools} />
    </MapContainer>
  );
}

export default Map;