import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const DriveTimeTable = ({ school, formatNumber }) => {
  const metrics = [
    { key: 'total_population_2024', label: 'Total Population 2024' },
    { key: 'total_population_2029', label: 'Total Population 2029' },
    { key: 'total_population_change_5yr', label: 'Population Change (5yr)', isPercentage: true },
    { key: 'k12_population_2024', label: 'K-12 Population 2024' },
    { key: 'k12_population_2029', label: 'K-12 Population 2029' },
    { key: 'k12_population_change_5yr', label: 'K-12 Population Change (5yr)', isPercentage: true },
    { key: 'households_2024', label: 'Households 2024' },
    { key: 'households_under_50k_percent', label: 'Households Under $50k', isPercentage: true },
    { key: 'white_percent', label: 'White Population', isPercentage: true },
    { key: 'black_percent', label: 'Black Population', isPercentage: true },
    { key: 'asian_percent', label: 'Asian Population', isPercentage: true },
    { key: 'hispanic_percent', label: 'Hispanic Population', isPercentage: true },
  ];

  const formatValue = (value, isPercentage) => {
    if (value === undefined || value === null) {
      return 'N/A';
    }
    if (isPercentage) {
      return `${Math.round(value * 100)}%`;
    }
    return formatNumber(value);
  };

  const safelyGetValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) ?? 'N/A';
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Metric</TableCell>
            <TableCell align="right">5 min</TableCell>
            <TableCell align="right">10 min</TableCell>
            <TableCell align="right">15 min</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((metric) => (
            <TableRow key={metric.key}>
              <TableCell component="th" scope="row">
                {metric.label}
              </TableCell>
              {[5, 10, 15].map((time) => (
                <TableCell key={time} align="right">
                  {formatValue(
                    safelyGetValue(school, `esriData.drive_times.${time}.${metric.key}`),
                    metric.isPercentage
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DriveTimeTable;