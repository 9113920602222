import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Grid, 
  Paper, 
  Typography, 
  Button, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import SchoolMap from './SchoolMap';
import SchoolEnrollmentProjectionChart from './SchoolEnrollmentProjectionChart';
import DriveTimeTable from './DriveTimeTable';

function SchoolDashboard({ school, onBack, apiUrl }) {
  const [visibleDriveTimes, setVisibleDriveTimes] = useState([5, 10, 15]);
  const [selectedGrade, setSelectedGrade] = useState('total');
  const [availableGrades, setAvailableGrades] = useState([]);
  const [historicalData, setHistoricalData] = useState(null);
  const [projectionData, setProjectionData] = useState(null);

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        const [historicalResponse, projectionResponse] = await Promise.all([
          fetch(`${apiUrl}/school/${school.ncessch}/nces-data`, { headers }),
          fetch(`${apiUrl}/school/${school.ncessch}/enrollment-projections`, { headers })
        ]);

        if (!historicalResponse.ok || !projectionResponse.ok) {
          throw new Error('Failed to fetch school data');
        }

        const historicalData = await historicalResponse.json();
        const projectionData = await projectionResponse.json();

        setHistoricalData(historicalData);
        setProjectionData(projectionData);

        // Extract all unique grades from both historical and projection data
        const historicalGrades = new Set(historicalData.enrollment_data.flatMap(year => Object.keys(year.grade_enrollment)));
        const projectionGrades = new Set(projectionData.enrollment_projections.flatMap(year => Object.keys(year.grade_enrollment)));
        const allGrades = new Set([...historicalGrades, ...projectionGrades]);

        const sortedGrades = Array.from(allGrades).sort((a, b) => {
          if (a === 'K') return -1;
          if (b === 'K') return 1;
          return parseInt(a) - parseInt(b);
        });

        setAvailableGrades(sortedGrades);
      } catch (error) {
        console.error('Error fetching school data:', error);
        // Handle error (e.g., show error message to user)
      }
    };

    if (school && school.ncessch) {
      fetchSchoolData();
    }
  }, [school, apiUrl]);

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  const toggleDriveTime = (driveTime) => {
    setVisibleDriveTimes(prev => 
      prev.includes(driveTime)
        ? prev.filter(dt => dt !== driveTime)
        : [...prev, driveTime]
    );
  };

  const formatGradeLabel = (grade) => {
    if (grade === 'K') return 'Kindergarten';
    if (grade === 'total') return 'Total';
    if (grade === 'PK') return 'Pre-Kindergarten';
    return `${grade}`;
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Container maxWidth="lg">
      <Button onClick={onBack} variant="contained" sx={{ mb: 2 }}>Back to All Schools</Button>
      <Typography variant="h4" gutterBottom>{school.name}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Enrollment Trends and Projections</Typography>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="grade-select-label">Grade</InputLabel>
                <Select
                  labelId="grade-select-label"
                  id="grade-select"
                  value={selectedGrade}
                  label="Grade"
                  onChange={handleGradeChange}
                >
                  <MenuItem value="total">Total</MenuItem>
                  {availableGrades.map((grade) => (
                    <MenuItem key={grade} value={grade}>
                      {formatGradeLabel(grade)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <SchoolEnrollmentProjectionChart 
              school={school} 
              apiUrl={apiUrl} 
              selectedGrade={selectedGrade}
              historicalData={historicalData}
              projectionData={projectionData}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>School Location and Service Area</Typography>
            <Box sx={{ flexGrow: 1, position: 'relative' }}>
              <SchoolMap school={school} visibleDriveTimes={visibleDriveTimes} />
            </Box>
            <Box sx={{ mt: 2 }}>
              {[5, 10, 15].map(driveTime => (
                <FormControlLabel
                  key={driveTime}
                  control={
                    <Checkbox
                      checked={visibleDriveTimes.includes(driveTime)}
                      onChange={() => toggleDriveTime(driveTime)}
                    />
                  }
                  label={`${driveTime} minutes`}
                />
              ))}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>Drive Time Data</Typography>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
              <DriveTimeTable school={school} formatNumber={formatNumber} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SchoolDashboard;