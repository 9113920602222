import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const customIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const driveTimeColors = {
  5: '#ff0000',  // Red
  10: '#00ff00', // Green
  15: '#0000ff'  // Blue
};

// Custom function to calculate polygon area
function calculatePolygonArea(coords) {
  if (!Array.isArray(coords) || coords.length < 3) {
    console.warn('Invalid coordinates for polygon area calculation:', coords);
    return 0;
  }
  let area = 0;
  for (let i = 0; i < coords.length - 1; i++) {
    let p1 = coords[i];
    let p2 = coords[i + 1];
    if (!Array.isArray(p1) || !Array.isArray(p2) || p1.length < 2 || p2.length < 2) {
      console.warn('Invalid coordinate pair:', p1, p2);
      continue;
    }
    area += (p2[0] - p1[0]) * (p2[1] + p1[1]);
  }
  return Math.abs(area) / 2;
}

function MapContent({ school, esriData, geoJsonData, visibleDriveTimes }) {
  const map = useMap();

  useEffect(() => {
    if (geoJsonData) {
      const allPolygons = Object.values(geoJsonData).filter(data => data !== null);
      if (allPolygons.length > 0) {
        const geoJsonLayer = L.geoJSON(allPolygons);
        const bounds = geoJsonLayer.getBounds();
        
        const coordinates = allPolygons.flatMap(poly => 
          poly.geometry && poly.geometry.coordinates && Array.isArray(poly.geometry.coordinates[0])
            ? poly.geometry.coordinates[0]
            : []
        );
        const polygonArea = calculatePolygonArea(coordinates);
        const mapArea = map.getSize().x * map.getSize().y;
        const paddingFactor = Math.min(Math.max(0.1, Math.sqrt(polygonArea / mapArea)), 0.3);
        
        const paddingX = Math.floor(map.getSize().x * paddingFactor);
        const paddingY = Math.floor(map.getSize().y * paddingFactor);
        
        map.fitBounds(bounds, {
          padding: [paddingX, paddingY],
          maxZoom: 15
        });
      }
    }
  }, [map, geoJsonData]);

  return (
    <>
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      />
      {school && school.latitude && school.longitude && (
        <Marker 
          position={[school.latitude, school.longitude]} 
          icon={customIcon}
        >
          <Popup>
            <strong>{school.name}</strong><br />
            Enrollment: {school.enrollment !== null ? school.enrollment : 'Not available'}
          </Popup>
        </Marker>
      )}
      {Object.entries(geoJsonData).map(([driveTime, data]) => 
        visibleDriveTimes.includes(parseInt(driveTime)) && data && (
          <GeoJSON 
            key={driveTime}
            data={data} 
            style={() => ({
              color: driveTimeColors[driveTime],
              weight: 2,
              fillColor: driveTimeColors[driveTime],
              fillOpacity: 0.2,
            })}
          />
        )
      )}
    </>
  );
}

function SchoolMap({ school, visibleDriveTimes }) {
  const [esriData, setEsriData] = useState(null);
  const [error, setError] = useState(null);
  const [geoJsonData, setGeoJsonData] = useState({});

  useEffect(() => {
    if (school && school.ncessch) {
      const token = localStorage.getItem('access_token');
      fetch(`${API_URL}/school/${school.ncessch}/esri-data`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Fetched ESRI data:', data);
          setEsriData(data);
          updateGeoJsonData(data);
        })
        .catch(error => {
          console.error('Error fetching ESRI data:', error);
          setError('Failed to load ESRI data. Please try again later.');
        });
    }
  }, [school]);

  const updateGeoJsonData = (data) => {
    const newGeoJsonData = {};
    [5, 10, 15].forEach(driveTime => {
      if (data.drive_times && data.drive_times[driveTime] && data.drive_times[driveTime].drive_time_polygon) {
        const polygonData = data.drive_times[driveTime].drive_time_polygon;
        if (typeof polygonData === 'string') {
          try {
            const parsedPolygon = JSON.parse(polygonData);
            newGeoJsonData[driveTime] = {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: parsedPolygon.rings || parsedPolygon
              }
            };
          } catch (error) {
            console.error(`Error parsing polygon data for ${driveTime} minutes:`, error);
            newGeoJsonData[driveTime] = null;
          }
        } else if (typeof polygonData === 'object' && polygonData.rings) {
          newGeoJsonData[driveTime] = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: polygonData.rings
            }
          };
        } else {
          console.warn(`Unexpected polygon data format for ${driveTime} minutes:`, polygonData);
          newGeoJsonData[driveTime] = null;
        }
      } else {
        console.warn(`No drive time polygon data available for ${driveTime} minutes`);
        newGeoJsonData[driveTime] = null;
      }
    });
    console.log('Created GeoJSON:', newGeoJsonData);
    setGeoJsonData(newGeoJsonData);
  };

  if (!school) {
    return <div>No school data available</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!esriData) {
    return <div>Loading ESRI data...</div>;
  }

  return (
    <MapContainer 
      key={school.ncessch}
      center={[school.latitude, school.longitude]} 
      zoom={13} 
      style={{ height: "400px", width: "100%" }}
    >
      <MapContent 
        school={school} 
        esriData={esriData} 
        geoJsonData={geoJsonData} 
        visibleDriveTimes={visibleDriveTimes}
      />
    </MapContainer>
  );
}

export default SchoolMap;