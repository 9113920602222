import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link as RouterLink, Navigate, useLocation } from 'react-router-dom';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container,
  Box,
  CircularProgress,
  Link,
  Stack
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MapIcon from '@mui/icons-material/Map';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import Map from './components/Map';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import AddressDataVisualization from './components/AddressDataVisualization';
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4338ca',
    },
    secondary: {
      main: '#14b8a6',
    },
    background: {
      default: '#ffffff', 
      paper: '#ffffff',  
    },
    text: {
      primary: '#000000', 
      secondary: '#666666', 
    },
  },
});

// Helper function to extract username from email
const getUsernameFromEmail = (email) => {
  return email.split('@')[0];
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const storedUserData = JSON.parse(localStorage.getItem('user'));
        setUserData(storedUserData);
      } else {
        setUserData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      setUser(null);
      setUserData(null);
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const AppBarWrapper = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login' || location.pathname === '/reset-password';

    if (isLoginPage) {
      return null;
    }

    return (
      <AppBar position="fixed" elevation={0} sx={{ backgroundColor: theme.palette.background.paper }}>
        <Toolbar>
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            <Box 
              component="img" 
              src="/images/edc_logo_circle.png" 
              alt="EDC Logo"
              sx={{ 
                height: 40,
                width: 'auto',
                mr: 2,
                display: 'block',
                objectFit: 'contain'
              }}    
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {user ? (
            <>
              <Button color="primary" component={RouterLink} to="/map" startIcon={<MapIcon />}>
                Map
              </Button>
              <Button color="primary" component={RouterLink} to="/address-visualization" startIcon={<DashboardCustomizeIcon />}>
                Custom Address
              </Button>
              <Button color="primary" startIcon={<AccountCircleIcon />}>
                {getUsernameFromEmail(userData ? userData.email : user.email)}
              </Button>
              <Button color="primary" onClick={handleLogout} startIcon={<LogoutIcon />}>
                Logout
              </Button>
            </>
          ) : (
            <Button color="inherit" component={RouterLink} to="/login" startIcon={<LoginIcon />}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    );
  };

  const Footer = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login' || location.pathname === '/reset-password';

    if (isLoginPage) {
      return null;
    }

    return (
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={1} alignItems="flex-end">
            <Typography variant="body2" color="text.secondary">
              © {new Date().getFullYear()} Education Data Collective
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Based in Denver, Colorado
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Providing data-driven insights for educational institutions
            </Typography>
            <Link 
              href="https://www.educationdatacollective.com" 
              color="inherit" 
              target="_blank" 
              rel="noopener"
              sx={{ textDecoration: 'none' }}
            >
              www.educationdatacollective.com
            </Link>
          </Stack>
        </Container>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppBarWrapper />
          <Container component="main" sx={{ mt: 10, mb: 4, flexGrow: 1 }}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route 
                path="/map" 
                element={
                  <ProtectedRoute user={user}>
                    <Map />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/" 
                element={
                  <ProtectedRoute user={user}>
                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                      <Typography variant="h4" component="h1" gutterBottom>
                        Welcome {user ? getUsernameFromEmail(user.email) : 'User'}
                      </Typography>
                      <Typography variant="body1">
                        This is a modern web application for data visualization and mapping.
                      </Typography>
                    </Box>
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/address-visualization" 
                element={
                  <ProtectedRoute user={user}>
                    <AddressDataVisualization />
                  </ProtectedRoute>
                } 
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Container>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;