import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { 
  Container, 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  CircularProgress
} from '@mui/material';
import { auth } from '../firebase';
import customLogger from '../utils/customLogger';
import getFirebaseErrorMessage from '../utils/firebaseErrorMessages';

const API_URL = process.env.REACT_APP_BACKEND_URL;

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      customLogger(`Attempting to log in user with email: ${email}`, 'info');
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Get the ID token
      const idToken = await user.getIdToken();
      
      customLogger(`Obtained ID token: ${idToken.substring(0, 10)}...`, 'info');
      
      // Verify token with your backend
      customLogger(`Sending token to backend: ${API_URL}/auth/verify-token`, 'info');
      const response = await fetch(`${API_URL}/auth/verify-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to verify token with backend: ${response.status} ${response.statusText} - ${errorText}`);
      }
      
      const userData = await response.json();
      
      // Store user data in localStorage
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('access_token', userData.access_token);
      
      customLogger('User logged in and verified successfully', 'info');

      // Force a page reload to update the app state
      window.location.href = '/';
    } catch (error) {
      customLogger(`Login failed: ${error.message}`, 'error');
      setError(getFirebaseErrorMessage(error.code) || error.message);
      // Sign out the user if backend verification fails
      await auth.signOut();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white' }}>
        <Box
          component="img"
          sx={{
            height: 250,
            mb: 2,
            animation: 'fadeIn 0.5s ease-in-out',
            '@keyframes fadeIn': {
              '0%': { opacity: 0, transform: 'translateY(-20px)' },
              '100%': { opacity: 1, transform: 'translateY(0)' },
            },
          }}
          alt="EDC Logo"
          src="/images/edc_logo.png"
        />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ 
              '& .MuiOutlinedInput-root': { 
                '& fieldset': { borderColor: '#14b8ab' },
                '&:hover fieldset': { borderColor: '#14b8ab' },
                '&.Mui-focused fieldset': { borderColor: '#14b8ab' },
              },
              '& .MuiInputLabel-root': { color: '#2a2e30' },
              '& .MuiInputBase-input': { color: '#2a2e30' },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ 
              '& .MuiOutlinedInput-root': { 
                '& fieldset': { borderColor: '#14b8ab' },
                '&:hover fieldset': { borderColor: '#14b8ab' },
                '&.Mui-focused fieldset': { borderColor: '#14b8ab' },
              },
              '& .MuiInputLabel-root': { color: '#2a2e30' },
              '& .MuiInputBase-input': { color: '#2a2e30' },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
              mt: 3, 
              mb: 2, 
              backgroundColor: '#4338ca',
              '&:hover': {
                backgroundColor: '#3730a3',
              },
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
          {error && (
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="/reset-password" style={{ textDecoration: 'none' }}>
              <Typography color="#14b8ab">
                Forgot Password?
              </Typography>
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;