import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function SchoolEnrollmentProjectionChart({ school, apiUrl, selectedGrade, historicalData, projectionData }) {
  const [chartData, setChartData] = useState(null);

  const formatGradeLabel = useCallback((grade) => {
    if (grade === 'K') return 'Kindergarten';
    if (grade === 'total') return 'Total';
    if (grade === 'PK') return 'Pre-Kindergarten';
    return `${grade}`;
  }, []);

  const combineEnrollmentData = useCallback((historical, projection, selectedGrade) => {
    const allYears = [...new Set([
      ...historical.enrollment_data.map(d => d.year),
      ...projection.enrollment_projections.map(d => d.year)
    ])].sort();

    let datasets = [];

    if (selectedGrade === 'total') {
      datasets = [
        {
          label: 'Total Historical Enrollment',
          data: allYears.map(year => {
            const entry = historical.enrollment_data.find(d => d.year === year);
            return entry ? entry.total_enrollment : null;
          }),
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          fill: false,
          tension: 0.1
        },
        {
          label: 'Total Projected Enrollment',
          data: allYears.map(year => {
            const entry = projection.enrollment_projections.find(d => d.year === year);
            return entry ? entry.total_enrollment : null;
          }),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          fill: false,
          tension: 0.1,
          borderDash: [5, 5]
        }
      ];
    } else {
      datasets = [
        {
          label: `${formatGradeLabel(selectedGrade)} Historical`,
          data: allYears.map(year => {
            const entry = historical.enrollment_data.find(d => d.year === year);
            return entry ? entry.grade_enrollment[selectedGrade] || null : null;
          }),
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          fill: false,
          tension: 0.1
        },
        {
          label: `${formatGradeLabel(selectedGrade)} Projected`,
          data: allYears.map(year => {
            const entry = projection.enrollment_projections.find(d => d.year === year);
            return entry ? entry.grade_enrollment[selectedGrade] || null : null;
          }),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          fill: false,
          tension: 0.1,
          borderDash: [5, 5]
        }
      ];
    }

    return {
      labels: allYears,
      datasets: datasets
    };
  }, [formatGradeLabel]);

  useEffect(() => {
    if (historicalData && projectionData) {
      const combinedData = combineEnrollmentData(historicalData, projectionData, selectedGrade);
      setChartData(combinedData);
    }
  }, [historicalData, projectionData, selectedGrade, combineEnrollmentData]);

  if (!school) {
    return <div>No school data available</div>;
  }

  if (!chartData) {
    return <div>Loading enrollment data...</div>;
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `${school.name} Enrollment Trends and Projections`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Enrollment'
        }
      },
      x: {
        title: {
          display: true,
          text: 'School Year'
        }
      }
    }
  };

  return (
    <div style={{ width: '100%', height: 400 }}>
      <Line data={chartData} options={options} />
    </div>
  );
}

export default SchoolEnrollmentProjectionChart;