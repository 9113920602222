import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, useMap } from 'react-leaflet';
import { Grid, Paper, Typography, TextField, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete, ToggleButton, ToggleButtonGroup, CircularProgress } from '@mui/material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapboxgl from 'mapbox-gl';
import debounce from 'lodash/debounce';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const API_URL = process.env.REACT_APP_BACKEND_URL;

const customIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const driveTimeColors = {
  5: '#ff0000',  // Red
  10: '#00ff00', // Green
  15: '#0000ff'  // Blue
};

function MapBounds({ addressData }) {
  const map = useMap();

  useEffect(() => {
    if (addressData && addressData.esri_data && addressData.esri_data[15] && addressData.esri_data[15].drive_time_polygon) {
      try {
        const geoJsonData = JSON.parse(addressData.esri_data[15].drive_time_polygon);
        console.log('GeoJSON data for bounds:', geoJsonData);
        
        const bounds = L.geoJSON(geoJsonData).getBounds();
        console.log('Calculated bounds:', bounds);
        
        map.fitBounds(bounds);
      } catch (error) {
        console.error('Error processing GeoJSON for bounds:', error);
      }
    } else {
      console.log('Missing or incomplete address data for bounds');
    }
  }, [addressData, map]);

  return null;
}

const AddressDataVisualization = () => {
  const [address, setAddress] = useState('');
  const [locationName, setLocationName] = useState('');
  const [addressData, setAddressData] = useState(null);
  const [error, setError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [visiblePolygons, setVisiblePolygons] = useState([5, 10, 15]);
  const [isProcessing, setIsProcessing] = useState(false);

  const fetchSuggestions = useCallback((input) => {
    if (input.length > 2) {
      fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(input)}.json?access_token=${mapboxgl.accessToken}&types=address&autocomplete=true&country=US`)
        .then(response => response.json())
        .then(data => {
          setSuggestions(data.features.map(feature => ({
            label: feature.place_name,
            value: feature.place_name,
          })));
        })
        .catch(error => {
          console.error('Error fetching address suggestions:', error);
        });
    } else {
      setSuggestions([]);
    }
  }, []);

  const debouncedFetchSuggestions = useCallback(
    (input) => {
      debounce((debouncedInput) => {
        fetchSuggestions(debouncedInput);
      }, 150)(input);
    },
    [fetchSuggestions]
  );

  const handleAddressChange = useCallback((event, newValue) => {
    if (typeof newValue === 'string') {
      setAddress(newValue);
      debouncedFetchSuggestions(newValue);
    } else if (newValue && newValue.value) {
      setAddress(newValue.value);
    }
  }, [debouncedFetchSuggestions, setAddress]);

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsProcessing(true);
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error('No access token found. Please log in again.');
      }
      const response = await fetch(`${API_URL}/process-address`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ address, locationName })
      });
      if (response.status === 401) {
        throw new Error('Unauthorized. Your session may have expired. Please log in again.');
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAddressData(data.data);
    } catch (error) {
      console.error('Error processing address:', error);
      setError(error.message || 'Failed to process address. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const formatNumber = (num) => {
    return num ? num.toLocaleString() : 'N/A';
  };

  const formatValue = (value, isPercentage) => {
    if (value === undefined || value === null) {
      return 'N/A';
    }
    if (isPercentage) {
      return `${Math.round(value * 100)}%`;
    }
    return formatNumber(value);
  };

  const handlePolygonToggle = (event, newVisiblePolygons) => {
    setVisiblePolygons(newVisiblePolygons);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Drive Time Data by Address
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Box component="form" onSubmit={handleAddressSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="locationName"
              label="Location Name"
              name="locationName"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
            />
            <Autocomplete
              freeSolo
              options={suggestions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  required
                  fullWidth
                  id="address"
                  label="Enter Address"
                  name="address"
                  autoComplete="off"
                />
              )}
              value={address}
              onChange={handleAddressChange}
              onInputChange={(event, newInputValue) => {
                setAddress(newInputValue);
                fetchSuggestions(newInputValue);
              }}
              filterOptions={(x) => x}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isProcessing}
            >
              {isProcessing ? <CircularProgress size={24} /> : 'Process Address'}
            </Button>
          </Box>
          {error && <Typography color="error">{error}</Typography>}
        </Paper>
      </Grid>
      {addressData && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {locationName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={visiblePolygons}
              onChange={handlePolygonToggle}
              aria-label="visible polygons"
            >
              <ToggleButton value={5} aria-label="5 minutes">
                5 min
              </ToggleButton>
              <ToggleButton value={10} aria-label="10 minutes">
                10 min
              </ToggleButton>
              <ToggleButton value={15} aria-label="15 minutes">
                15 min
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} style={{ padding: '20px', height: '600px' }}>
              <MapContainer 
                center={[addressData.latitude, addressData.longitude]} 
                zoom={13} 
                style={{ height: "100%", width: "100%" }}
              >
                <TileLayer
                  url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                  attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                />
                <Marker 
                  position={[addressData.latitude, addressData.longitude]}
                  icon={customIcon}
                >
                  <Popup>{addressData.address}</Popup>
                </Marker>
                {Object.entries(addressData.esri_data).map(([driveTime, data]) => {
                  if (data.drive_time_polygon && visiblePolygons.includes(parseInt(driveTime))) {
                    try {
                      const parsedGeoJson = JSON.parse(data.drive_time_polygon);
                      console.log(`Parsed GeoJSON for ${driveTime} minutes:`, parsedGeoJson);
                      
                      // Ensure the GeoJSON is a proper FeatureCollection
                      const geoJsonData = parsedGeoJson.type === 'FeatureCollection' 
                        ? parsedGeoJson 
                        : {
                            type: "FeatureCollection",
                            features: [parsedGeoJson]
                          };
                      
                      console.log(`Structured GeoJSON for ${driveTime} minutes:`, geoJsonData);
                      
                      return (
                        <GeoJSON 
                          key={driveTime}
                          data={geoJsonData}
                          style={() => ({
                            color: driveTimeColors[driveTime],
                            weight: 2,
                            fillColor: driveTimeColors[driveTime],
                            fillOpacity: 0.2,
                          })}
                        />
                      );
                    } catch (error) {
                      console.error(`Error parsing GeoJSON for ${driveTime} minutes:`, error);
                      return null;
                    }
                  }
                  return null;
                })}
                <MapBounds addressData={addressData} />
              </MapContainer>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} style={{ padding: '20px', height: '600px', overflowY: 'auto' }}>
              <Typography variant="h6" gutterBottom>
                Drive Time Data
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Metric</TableCell>
                      <TableCell align="right">5 min</TableCell>
                      <TableCell align="right">10 min</TableCell>
                      <TableCell align="right">15 min</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[
                      { key: 'total_population_2024', label: 'Total Population 2024' },
                      { key: 'total_population_2029', label: 'Total Population 2029' },
                      { key: 'total_population_change_5yr', label: 'Population Change (5yr)', isPercentage: true },
                      { key: 'k12_population_2024', label: 'K-12 Population 2024' },
                      { key: 'k12_population_2029', label: 'K-12 Population 2029' },
                      { key: 'k12_population_change_5yr', label: 'K-12 Population Change (5yr)', isPercentage: true },
                      { key: 'households_2024', label: 'Households 2024' },
                      { key: 'households_under_50k_percent', label: 'Households Under $50k', isPercentage: true },
                      { key: 'white_percent', label: 'White Population', isPercentage: true },
                      { key: 'black_percent', label: 'Black Population', isPercentage: true },
                      { key: 'asian_percent', label: 'Asian Population', isPercentage: true },
                      { key: 'hispanic_percent', label: 'Hispanic Population', isPercentage: true },
                    ].map((metric) => (
                      <TableRow key={metric.key}>
                        <TableCell component="th" scope="row">
                          {metric.label}
                        </TableCell>
                        {[5, 10, 15].map((time) => (
                          <TableCell key={time} align="right">
                            {formatValue(
                              addressData.esri_data[time][metric.key],
                              metric.isPercentage
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AddressDataVisualization;